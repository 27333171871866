import { Text } from '@/components/@base/typography';

import { STEP, useAuthStepStore } from '@/store/auth.store';

import { pageLevelLocalization } from '@/constant/localization';

const { login: loginLocalization } = pageLevelLocalization;

export default function RecoveryLink() {
  const { setStep, userNotExist, setRecoveryMode, recoveryMode } = useAuthStepStore();
  const onClickHandler = () => {
    setStep(STEP.AUTH_STEP_START);
    setRecoveryMode(true);
  };
  if (userNotExist || recoveryMode) {
    return null;
  }
  return (
    <Text
      onClick={onClickHandler}
      bold
      className='cursor-pointer border-b border-dashed border-[#6097F3] text-[12px] text-[#6097F3]'
    >
      {loginLocalization.passwordRecovery}
    </Text>
  );
}
