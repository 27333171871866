'use client';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { Suspense, useEffect, useState } from 'react';
import Login from 'src/components/app/auth/components/login';
import OtpForm from 'src/components/app/auth/components/otp';
import Password from 'src/components/app/auth/components/password';

import { setCookie } from '@/lib/cookies';
import { APIErrorResponseGenerator } from '@/lib/helper';

import { USER_TYPE } from '@/components/@base/@helpers/types';
import Container from '@/components/@base/container';
import Spinner from '@/components/@base/spinner';
import { showToast } from '@/components/@base/toast/show-toast';
import {
  usePostChangePassword,
  usePostUserLogin,
  usePostUserValidation,
  usePostValidateCode,
  usePostVerificationCode,
} from '@/components/app/auth/services/hooks';

import { STEP, useAuthStepStore } from '@/store/auth.store';
import useCommonModalStore from '@/store/common-modal-store';

import { GET_ADD_TO_CART_INFO, GET_CART, GET_PROFILE } from '@/constant/query-key';
import { PRECONDITION } from '@/constant/status-codes';
import StorageKey from '@/constant/storage-key';

export default function Auth() {
  const queryClient = useQueryClient();
  const queries = [[GET_PROFILE], [GET_CART], [GET_ADD_TO_CART_INFO]];
  const {
    step,
    setStep,
    username,
    phoneNumber,
    enterByPassword,
    recoveryMode,
    setUserNotExist,
    userNotExist,
    setTwoFactor,
  } = useAuthStepStore();
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const { mutate: postUserValidation, isPending: postUserValidationPending } =
    usePostUserValidation();
  const { mutate: postUserLogin, isPending: postUserLoginPending } = usePostUserLogin();
  const { mutate: postValidateCode, isPending: postValidateCodePending } =
    usePostValidateCode();
  const { mutate: postVerificationCode, isPending: postVerificationCodePending } =
    usePostVerificationCode();
  const { mutate: postChangePassword, isPending: postChangePasswordPending } =
    usePostChangePassword();
  const { setShow } = useCommonModalStore();

  const [afterLoginRoute, setAfterLoginRoute] = useState('/');

  const [isModal, setIsModal] = useState(false);

  // Function to handle redirection after login
  const redirectToFirstStep = (status?: number) => {
    if (step === STEP.AUTH_STEP_START) {
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else if (status === PRECONDITION) {
      setStep(STEP.AUTH_STEP_START);
    }
  };

  // Handlers
  const handleSendCode = (userDoesNotExist?: boolean) => {
    postVerificationCode(
      { username: phoneNumber || username },
      {
        onSuccess(data) {
          if (data.error) {
            showToast({ message: data.error, type: 'error' });
          } else {
            setStep(data.nextStep);
            showToast({ message: data?.message, type: 'success' });
          }
        },
        onError(e) {
          if (e instanceof AxiosError) {
            redirectToFirstStep(e?.response?.status);
            if (userDoesNotExist) {
              setStep(STEP.AUTH_STEP_SEND_CODE);
            }
            showToast(APIErrorResponseGenerator(e));
          }
        },
      },
    );
  };

  const handleUserValidation = async (recaptcha: string, callback: () => void) => {
    const body: any = { username: username || phoneNumber };
    if (recaptcha) {
      body['reCaptcha'] = recaptcha;
    }
    postUserValidation(body, {
      onSuccess(data) {
        if (callback) {
          callback();
        }
        setTwoFactor(data.twoFactorAuth);
        if (data?.error) {
          setStep(data.nextStep);
        } else if (recoveryMode) {
          handleSendCode(!data.userExists);
        } else if (enterByPassword && data.userExists) {
          setStep(STEP.AUTH_STEP_PASSWORD_INPUT);
        } else {
          if (data.nextStep === STEP.AUTH_STEP_SEND_CODE) {
            setUserNotExist(!data.userExists);
            handleSendCode(!data.userExists);
          } else {
            setStep(data.nextStep);
          }
        }
        if (data.error) {
          showToast({ message: data.error, type: 'error' });
        }
      },
      onError(e) {
        if (e instanceof AxiosError) {
          redirectToFirstStep(e?.response?.status);
          showToast(APIErrorResponseGenerator(e));
        }
      },
    });
  };

  const handleCodeValidation = (code: string, callback: () => void) => {
    const body: any = { code };
    body['username'] = username || phoneNumber;
    postValidateCode(body, {
      onSuccess: async (data) => {
        setCookie(StorageKey.TOKEN, data?.accessToken, {
          expires: new Date(data.expiresIn * 1000),
          path: '/',
          domain: process.env.NEXT_PUBLIC_WEB_TOKEN_DOMAIN,
        });
        if (userNotExist || recoveryMode) {
          setStep(STEP.AUTH_STEP_PASSWORD_INPUT);
        } else {
          setStep(data.nextStep);
          try {
            await Promise.all([
              queries.map((queryKey) => queryClient.refetchQueries({ queryKey })),
            ]);
          } catch (error) {
            console.error('Error refetching queries:', error);
          }

          if (data.userType && data.userType === USER_TYPE.VENDOR && afterLoginRoute === '/') {
            if (data.newDashboard){
              router.push('/panel');
            }else{
              router.push('/dashboard');
            }
          } else {
            router.push(afterLoginRoute);
          }
        }
      },
      onError(e) {
        if (e instanceof AxiosError) {
          redirectToFirstStep(e?.response?.status);
          callback();
          showToast(APIErrorResponseGenerator(e));
        }
      },
    });
  };

  const handleUserLogin = (password: string, confirmPassword?: string, twoFactor?: string) => {
    if (confirmPassword) {
      handleChangePassword(password, confirmPassword);
    } else {
      const body: any = { username: username || phoneNumber, password };
      if (twoFactor) {
        body['2fa_code'] = twoFactor;
      }
      postUserLogin(body, {
        onSuccess: async (data) => {
          setStep(data.nextStep);
          setCookie(StorageKey.TOKEN, data?.accessToken, {
            expires: new Date(data.expiresIn * 1000),
            path: '/',
            domain: process.env.NEXT_PUBLIC_WEB_TOKEN_DOMAIN,
          });
          try {
            await Promise.all([
              queries.map((queryKey) => queryClient.refetchQueries({ queryKey })),
            ]);
          } catch (error) {
            console.error('Error refetching queries:', error);
          }
          if (data.userType && data.userType === USER_TYPE.VENDOR && afterLoginRoute === '/') {
            if (data.newDashboard){
              router.push('/panel');
            }else{
              router.push('/dashboard');
            }
          } else {
            router.push(afterLoginRoute);
          }
        },
        onError(e) {
          if (e instanceof AxiosError) {
            redirectToFirstStep(e?.response?.status);
            showToast(APIErrorResponseGenerator(e));
          }
        },
      });
    }
  };

  const handleChangePassword = (password: string, confirmPassword: string) => {
    postChangePassword(
      {
        password,
        confirm_password: confirmPassword,
      },
      {
        onSuccess: async (data) => {
          showToast({ message: data.message, type: 'success' });
          try {
            await Promise.all([
              queries.map((queryKey) => queryClient.refetchQueries({ queryKey })),
            ]);
          } catch (error) {
            console.error('Error refetching queries:', error);
          }
          router.push(afterLoginRoute);
          if (isModal) {
            setShow(false);
            setIsLoading(false);
          }
        },
        onError(e) {
          if (e instanceof AxiosError) {
            redirectToFirstStep(e?.response?.status);
            showToast(APIErrorResponseGenerator(e));
          }
        },
      },
    );
  };

  useEffect(() => {
    if (step === STEP.AUTH_STEP_SHOW_DASHBOARD) {
      setIsLoading(true);
    }
    if (step === STEP.AUTH_STEP_SHOW_DASHBOARD && isModal) {
      setShow(false);
    }
  }, [step, afterLoginRoute, setShow]);

  if (isLoading && isModal) {
    return <Spinner />;
  }
  if (isLoading && !isModal) {
    return <Spinner className='fixed !h-screen bg-black' />;
  }

  return (
    <>
      <Suspense>
        <AfterLoginRouteComponent
          setAfterLoginRoute={setAfterLoginRoute}
          setIsModal={setIsModal}
        />
      </Suspense>
      <Container center className='w-full flex-col gap-7'>
        {(step === STEP.AUTH_STEP_START ||
          step === STEP.AUTH_STEP_GET_MOBILE ||
          step === STEP.AUTH_STEP_GET_EMAIL) && (
          <Login
            onSubmit={handleUserValidation}
            isLoading={postUserValidationPending || postVerificationCodePending}
          />
        )}
        {step === STEP.AUTH_STEP_SEND_CODE && (
          <OtpForm
            handleSendCode={handleSendCode}
            onSubmit={handleCodeValidation}
            isLoading={postValidateCodePending}
          />
        )}
        {(step === STEP.AUTH_STEP_PASSWORD_INPUT ||
          step === STEP.AUTH_STEP_CONFIRM_PASSWORD_INPUT) && (
          <Password
            onSubmit={handleUserLogin}
            isLoading={postUserLoginPending || postChangePasswordPending}
          />
        )}
        {isLoading && isModal && <Spinner />}
        {isLoading && !isModal && <Spinner className='fixed !h-screen bg-black' />}
      </Container>
    </>
  );
}

function AfterLoginRouteComponent({
  setAfterLoginRoute,
  setIsModal,
}: {
  setAfterLoginRoute: React.Dispatch<React.SetStateAction<string>>;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    const allowedPathsRegex =
      /^((\/panel.*)|(\/dashboard.*)|(\/web.*)|(\/store.*)|(\/cart)|(\/search.*))$/;

    let route = '/';
    const afterLoginParam = searchParams.get('afterLogin');

    if (afterLoginParam) {
      if (allowedPathsRegex.test(afterLoginParam)) {
        route = afterLoginParam;
      } else {
        route = '/';
      }
    } else if (pathname !== '/login') {
      if (allowedPathsRegex.test(pathname)) {
        route = pathname;
        setIsModal(true);
      } else {
        route = '/';
      }
    } else {
      route = '/';
    }

    setAfterLoginRoute(route);
  }, [pathname, searchParams, setAfterLoginRoute, setIsModal]);

  return null;
}
