import axios from 'axios';

import { getCookie } from '@/lib/cookies';

import { API_GATE_WAY } from '@/constant/routes';
import StorageKey from '@/constant/storage-key';

const baseURL = API_GATE_WAY;

const axiosWithHeaders = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosWithHeaders.interceptors.request.use((config) => {
  const token = getCookie(StorageKey.TOKEN);
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

export default axiosWithHeaders;
