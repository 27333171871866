import React, { forwardRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import cn from '@/lib/clsxm';

import Container from '@/components/@base/container';
type CaptchaProps = {
  id?: string;
  className?: string;
  onChange: (field: string, value: string | null) => void;
};

const Captcha =  forwardRef<ReCAPTCHA, CaptchaProps>(({  ...props }, ref) => {
  const { onChange,className } = props;
  return (
    <Container className={cn('mt-4', className)}>
      <ReCAPTCHA
        {...props}
        ref={ref}
        size='normal'
        hl='fa'
        sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
        onChange={(val) => onChange('recaptcha', val)}
      />
    </Container>
  );
});

export default Captcha;
