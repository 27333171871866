'use client';
import { HiOutlineLockClosed } from 'react-icons/hi';

import Button from '@/components/@base/button';
import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';
import Modal from '@/components/app/auth/components/login/terms/modal';

import useCommonModalStore from '@/store/common-modal-store';

import ICON_SIZE from '@/constant/icon-size-color';
import { pageLevelLocalization } from '@/constant/localization';

const { login: loginLocalization } = pageLevelLocalization;

export default function Terms() {
  const { setShow } = useCommonModalStore();

  const openTermsModal = () => {
    setShow(true, {
      Body() {
        return <Modal />;
      },
      DialogPanelProps: { className: 'w-full max-w-[452px] !p-10' },
    });
  };
  return (
    <Container>
      <Button
        className='h-[31px] gap-1 rounded-md bg-[#878F9B]'
        onClick={openTermsModal}
        center
        Icon={<HiOutlineLockClosed color='#FFFFFF' size={ICON_SIZE.sm} />}
      >
        <Text bold className='pt-1 text-[12px] text-white'>
          {loginLocalization.policy}
        </Text>
      </Button>
    </Container>
  );
}
