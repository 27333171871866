import Container from '@/components/@base/container';
import { Props } from '@/components/@base/input/textarea/type';

export default function Textarea({ className, children, ...props }: Props) {
  return (
    <textarea className={className} {...props}>
      {children}
    </textarea>
  );
}
