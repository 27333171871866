import { useCallback, useEffect, useState } from 'react';

import localization from '@/constant/localization';

export function useOtpTimer(initialSeconds = 120, handleSendCode) {
  const [showTimer, setShowTimer] = useState(true);
  const [seconds, setSeconds] = useState(initialSeconds);

  const handleTimerComplete = useCallback(() => {
    setShowTimer(false);
  }, []);

  const handleResendClick = () => {
    if (!showTimer) {
      setShowTimer(true);
      setSeconds(120);
      handleSendCode();
    }
  };

  useEffect(() => {
    let intervalId;
    if (seconds > 0) {
      intervalId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else {
      handleTimerComplete();
    }

    return () => clearInterval(intervalId);
  }, [seconds, handleTimerComplete]);

  const formatTime = () => {
    return `${seconds} ${localization.timeUnits.second}`;
  };

  return {
    showTimer,
    seconds,
    handleResendClick,
    formatTime,
  };
}
