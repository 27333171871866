'use client';

import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import OTPInput from 'react-otp-input';
import LoginOptions from 'src/components/app/auth/components/@shared/login-options';

import Container from '@/components/@base/container';
import { Input } from '@/components/@base/input';
import AuthForm from '@/components/app/auth/components/@shared/auth-form';
import { LOGIN_BY } from '@/components/app/auth/components/@shared/login-options/type';
import ResendOtp from '@/components/app/auth/components/@shared/resend-otp';

import { STEP, useAuthStepStore } from '@/store/auth.store';

import { pageLevelLocalization } from '@/constant/localization';

import Props from 'src/components/app/auth/components/otp/type';

const { login: loginLocalization } = pageLevelLocalization;

export default function OtpForm({ onSubmit, handleSendCode, isLoading }:Props) {
  const [otp, setOtp] = useState('');
  const { phoneNumber, setStep } = useAuthStepStore();
  const backHandler = () => {
    setStep(STEP.AUTH_STEP_START);
  };
  const onSubmitHandler = (otp) => {
    onSubmit(otp, () => setOtp(''));
  };
  useEffect(() => {
    if (otp.length === 5) {
      onSubmitHandler(otp);
    }
  }, [otp]);
  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        otp: null,
      }}
      onSubmit={(values) => onSubmitHandler(otp)}
    >
      {({ values, handleChange, setFieldValue }) => {
        return (
          <Form>
            <AuthForm
              title={loginLocalization.enterOtp}
              submitText={loginLocalization.otpVerification}
              phoneNumber={phoneNumber}
              isLoading={isLoading}
              backHandler={backHandler}
              actions={
                <Container center className='items-end justify-between pt-5'>
                  <ResendOtp
                    handleSendCode={() => {
                      handleSendCode();
                      setOtp('');
                    }}
                  />
                  <LoginOptions loginBy={LOGIN_BY.PASSWORD} />
                </Container>
              }
            >
              <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={5}
                inputType='tel'
                shouldAutoFocus
                containerStyle={{ display: 'flex', gap: 12, direction: 'ltr' }}
                renderInput={(props) => (
                  <Input
                    {...props}
                    className='h-[60px] !w-[60px] text-2xl focus:border-[#FFC87B] focus:shadow-[0px_2px_6px_0px_rgba(255,150,6,0.19)]'
                  />
                )}
              />
            </AuthForm>
          </Form>
        );
      }}
    </Formik>
  );
}
