import { IoClose } from 'react-icons/io5';

import cn from '@/lib/clsxm';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';
import XImage from '@/components/@base/x-image';

import useCommonModalStore from '@/store/common-modal-store';

import { TERMS } from '@/constant/iterable-items';
import { pageLevelLocalization } from '@/constant/localization';

const { login: loginLocalization } = pageLevelLocalization;

export default function Modal() {
  const { setShow } = useCommonModalStore();

  return (
    <Container center className='flex-col gap-[50px] pb-[30px] [&>*]:w-full'>
      <Container center className='justify-between'>
        <Text size={SIZE_ENUM.LG} className='text-[#424244]'>
          {loginLocalization.policy}
        </Text>
        <IoClose
          color='#7E899B'
          className='cursor-pointer'
          size={25}
          fontWeight={2}
          onClick={() => setShow(false)}
        />
      </Container>
      <Container center className='flex-col gap-8'>
        {TERMS.map((item, index) => (
          <Container key={item.id} center className='justify-start gap-5'>
            <XImage
              src={item.icon}
              alt={item.text}
              className={cn(
                index == 2 && 'w-[45px]',
                index == 1 && 'w-[50px]',
                index == 0 && 'w-[50px]',
              )}
            />
            <Text medium className='text-[15px] text-[#424244]'>
              {item.text}
            </Text>
          </Container>
        ))}
      </Container>
    </Container>
  );
}
