import { forwardRef } from 'react';

import cn from '@/lib/clsxm';

import { COLOR_ENUM, SIZE_ENUM } from '@/components/@base/@helpers/types';
import Container from '@/components/@base/container';
import Props, { INPUT_TYPES } from '@/components/@base/input/input/type';
import { Text } from '@/components/@base/typography';

function Input(
  {
    className,
    containerClassName,
    type = INPUT_TYPES.TEXT,
    size = SIZE_ENUM.MD,
    value = '',
    id = '',
    label,
    Icon,
    errorMessage,
    PlaceholderIcon,
    ...other
  }: Props,
  ref,
) {
  return (
    <Container
      className={cn(
        (Icon || PlaceholderIcon) &&
          'flex items-center rounded-md border-athens-gray p-1 shadow-md transition duration-100',
        containerClassName,
      )}
    >
      {errorMessage && (
        <Text size={SIZE_ENUM.XS} color={COLOR_ENUM.ERROR}>
          {errorMessage}
        </Text>
      )}
      {label && (
        <label htmlFor={id} className='mb-2 block text-sm font-medium text-gray-900'>
          {label}
        </label>
      )}
      {PlaceholderIcon && <PlaceholderIcon />}
      <input
        className={cn(
          'block w-full rounded-lg border border-gray-300 bg-white p-2.5 text-sm text-[#76767C] outline-none transition duration-100 focus:border-[#FF9606] focus:shadow-[0px_20px_60px_-15px_rgba(0,0,0,0.15)] focus:ring-[#FF9606]',
          size === SIZE_ENUM.XXS && 'h-4',
          size === SIZE_ENUM.XS && 'h-6',
          size === SIZE_ENUM.SM && 'h-7',
          size === SIZE_ENUM.MD && 'h-8',
          size === SIZE_ENUM.LG && 'h-10',
          size === SIZE_ENUM.XL && 'h-12',
          Icon && 'pr-8',
          errorMessage && 'border-error focus:border-error border-solid focus:ring-0',
          className,
        )}
        ref={ref}
        value={value}
        type={type}
        id={id}
        {...other}
      />
      {Icon && <Icon />}
    </Container>
  );
}

export default forwardRef(Input);
