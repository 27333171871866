import { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';

import Button from '@/components/@base/button';
import { Input } from '@/components/@base/input';

import ICON_SIZE, { ICON_COLOR } from '@/constant/icon-size-color';
import { pageLevelLocalization } from '@/constant/localization';

import Props from '@/components/app/auth/components/@shared/password-input/type';

const { login: LoginLocalization } = pageLevelLocalization;

export default function PasswordInput({
  confirmPass,
  value,
  handleInputChange,
  error,
  touched,
}:Props) {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Input
      containerClassName='focus-within:border-[#FFC87B] focus-within:shadow-[0px_2px_6px_0px_rgba(255,150,6,0.19)] relative shadow-[0px_2px_8px_0px_rgba(73,75,84,0.05)] h-[60px] p-1 border border-[#E2E2E2] w-full'
      id={confirmPass ? 'confirmPassword' : 'password'}
      type={showPassword ? 'text' : 'password'}
      className='border-0 text-[15px] text-[#76767C] placeholder-[#76767C] placeholder:text-[15px] placeholder:font-medium'
      placeholder={LoginLocalization.enterYourPass} // Use a placeholder or a localizable text
      onChange={handleInputChange}
      value={value}
      Icon={() => (
        <Button
          onClick={togglePasswordVisibility}
          className='group h-[42px] w-[42px] rounded-md bg-[#F9FAFC] hover:bg-[#878F9B] focus:bg-[#5B5C60]'
        >
          {showPassword ? (
            <FiEyeOff
              size={ICON_SIZE.sm}
              color={ICON_COLOR.gray}
              className='transition duration-300 group-hover:!text-white'
            />
          ) : (
            <FiEye
              size={ICON_SIZE.sm}
              color={ICON_COLOR.gray}
              className='transition duration-300 group-hover:!text-white'
            />
          )}
        </Button>
      )}
    />
    // {touched && error ? <div className='mt-1 text-sm text-red-600'>{error}</div> : null}
  );
}
