import { Combobox as HeadlessCombobox } from '@headlessui/react';

import Button from '@/components/@base/input/combobox/button/index';
import Input from '@/components/@base/input/combobox/input/index';
import Option from '@/components/@base/input/combobox/option/index';
import Options from '@/components/@base/input/combobox/options/index';
import Props from '@/components/@base/input/combobox/type';

export default function Combobox({ children, ...props }: Props) {
  return <HeadlessCombobox {...props}>{children}</HeadlessCombobox>;
}

export {
  Button as ComboboxButton,
  Input as ComboboxInput,
  Option as ComboboxOption,
  Options as ComboboxOptions,
};
