import { uuid4 } from '@sentry/utils';

import { SORT_MODE } from '@/components/app/archive/helpers';

import assets from '@/constant/images-list';
import imagesList from '@/constant/images-list';
import generator from '@/constant/iterable-items/generator';
import localization, { pageLevelLocalization } from '@/constant/localization';
import ImageModel from '@/models/image.model';

const { images, icons } = assets;

const {
  home: homeLocalization,
  login: loginLocalization,
  archive: archiveLocalization,
} = pageLevelLocalization;

// obligation items generator

export const OBLIGATIONS = generator<{
  icon: string;
  title: string;
}>(
  [
    [icons.moneyBackGuarantee, homeLocalization.moneyBackGuarantee],
    [icons.automaticUpdate, homeLocalization.permanentSupport],
    [icons.permanentSupport, homeLocalization.automaticUpdate],
  ],
  ['icon', 'title'],
);
export const TERMS = generator<{
  icon: string;
  text: string;
}>(
  [
    [icons.termBrowser, loginLocalization.recommendedBrowsers],
    [icons.termPass, loginLocalization.checkingPassword],
    [icons.termEmail, loginLocalization.zhkPreventUsingEmail],
  ],
  ['icon', 'text'],
);

export const FOOTER_ACHIEVEMENTS_LIST = generator<{
  icon: ImageModel;
  backgroundColor: string;
  title: string;
  slug: string;
}>(
  [
    [
      {
        id: uuid4(),
        publicLink: imagesList.icons.zhkGreenSmLogo,
        title: '',
        description: '',
      },
      '#A3D65733',
      '',
      '',
    ],
    [
      {
        id: uuid4(),
        publicLink: imagesList.icons.verified,
        title: '',
        description: '',
      },
      '#363D48',
      '',
      '',
    ],
    [
      {
        id: uuid4(),
        publicLink: imagesList.icons.irMap,
        title: '',
        description: '',
      },
      '#3A3125',
      '',
      '',
    ],
  ],
  ['icon', 'backgroundColor', 'title', 'slug'],
);

export const SORT_ITEMS = generator<{
  label: string;
  sortKey: string;
}>(
  [
    [localization.mostUpToDate, SORT_MODE.MOST_UPDATE],
    [localization.newest, SORT_MODE.NEWEST],
    [localization.topSale, SORT_MODE.TOP_SALE],
    [localization.specialSale, SORT_MODE.SPECIAL_SALE],
  ],
  ['label', 'sortKey'],
);

export const MEDAL_BASED_FILTERS = generator<{
  title: string;
  filterKey: string;
  icon: string;
}>(
  [
    [
      archiveLocalization.filter.mainProducts,
      archiveLocalization.filter.original,
      imagesList.icons.mainProductsMedal,
    ],
    [
      archiveLocalization.filter.specialProducts,
      archiveLocalization.filter.featured,
      imagesList.icons.specialProductsMedal,
    ],
    [
      archiveLocalization.filter.iranianProducts,
      archiveLocalization.filter.iranian,
      imagesList.icons.iranianProductsMedal,
    ],
    [
      archiveLocalization.filter.zhaketLicencedProducts,
      archiveLocalization.filter.licensed,
      imagesList.icons.zhaketLicencedProductsMedal,
    ],
  ],
  ['title', 'filterKey', 'icon'],
);
export const DEMO_FILTERS = generator<{
  title: string;
  value: string;
  listPath: string;
}>(
  [
    [localization.all, '', '/web/category/wordpress-themes/?search_demo=true'],
    [
      localization.personal,
      '5dcaacbfeaec37018b508a67',
      '/web/tag/personal-wordpress-theme/?search_demo=true',
    ],
    [
      localization.ecommerceWoocommerce,
      '5dcaacbfeaec37018b508a6c',
      '/web/category/ecommerce-woocommerce/?search_demo=true',
    ],
    [
      localization.corporate,
      '5dcaacbfeaec37018b508a68',
      '/web/category/corporate/?search_demo=true',
    ],
  ],
  ['title', 'value', 'listPath'],
);
