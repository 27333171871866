import { IoIosArrowBack } from 'react-icons/io';
import { MdOutlineModeEditOutline } from 'react-icons/md';

import cn from '@/lib/clsxm';

import { SIZE_ENUM } from '@/components/@base/@helpers/types';
import Button from '@/components/@base/button';
import { BUTTON_TYPE, VARIANT_ENUM } from '@/components/@base/button/type';
import Card, { CardAction, CardContent } from '@/components/@base/card';
import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';
import Props from '@/components/app/auth/components/@shared/auth-form/type';
import ZhkLogo from '@/components/shared/components/logo';
import { LOGO_SIZE } from '@/components/shared/components/logo/type';

import { STEP, useAuthStepStore } from '@/store/auth.store';

import ICON_SIZE, { ICON_COLOR } from '@/constant/icon-size-color';
import { pageLevelLocalization } from '@/constant/localization';

const { login: loginLocalization } = pageLevelLocalization;

export default function AuthForm({
  title,
  submitText,
  actions,
  children,
  phoneNumber,
  backHandler,
  hideBackButton,
  isLoading,
}: Props) {
  const { setStep } = useAuthStepStore();
  return (
    <Card className='relative w-[400px] items-center justify-start bg-white p-[25px] md:w-[450px] md:p-[50px] [&>*]:w-full'>
      <CardContent center className='flex-col p-0 text-red-500'>
        <ZhkLogo size={LOGO_SIZE.LG} darkMode />
        <Text
          size={SIZE_ENUM.LG}
          bold
          className={cn('pb-12 pt-5 text-[#424244]', phoneNumber && 'pb-1')}
        >
          {title}
        </Text>
        {!hideBackButton && (
          <Button
            variant={VARIANT_ENUM.SOUL}
            onClick={backHandler}
            className='group absolute left-4 top-4 h-10 w-10'
          >
            <IoIosArrowBack
              color={ICON_COLOR.gray}
              size={ICON_SIZE.md}
              className='transition duration-300 group-hover:fill-white'
            />
          </Button>
        )}
        {phoneNumber && (
          <Container center className='gap-1 pb-10'>
            <Text className='text-[#76767C]'>
              {loginLocalization.codeSentToThis(phoneNumber)}
            </Text>
            <Container
              onClick={() => setStep(STEP.AUTH_STEP_START)}
              className='flex cursor-pointer items-center justify-center border-b-2 border-dashed border-b-[#81CF33] text-[#81CF33]'
            >
              <MdOutlineModeEditOutline />
              <Text>{loginLocalization.editPhone}</Text>
            </Container>
          </Container>
        )}
        {children}
      </CardContent>
      <CardAction className='p-0 pt-4'>
        <Button
          size={SIZE_ENUM.MD}
          type={BUTTON_TYPE.SUBMIT}
          className='h-[60px] w-full rounded-md text-[15px] font-bold text-white hover:bg-[#EB8800] hover:text-[white]'
          isLoading={isLoading}
        >
          {submitText}
        </Button>
        {actions}
      </CardAction>
    </Card>
  );
}
