import { useMutation, useQuery } from '@tanstack/react-query';

import {
  getGuestToken,
  getRecaptchaStatus,
  postChangePassword,
  postUserLogin,
  postUserValidation,
  postValidateCode,
  postVerificationCode,
} from '@/components/app/auth/services';
import {
  ChangePasswordType,
  SendVerificationCodeType,
  UserLoginType,
  UserValidationType,
  ValidateCodeType,
} from '@/components/app/auth/services/types';

import {
  GET_GUEST_TOKEN,
  GET_RECAPTCHA_STATUS,
  POST_CHANGE_PASSWORD,
  POST_USER_LOGIN,
  POST_USER_VALIDATION,
  POST_VALIDATE_CODE,
  POST_VERIFICATION_CODE,
} from '@/constant/query-key';

export function usePostUserValidation() {
  return useMutation({
    mutationFn: (params: UserValidationType) => postUserValidation(params),
    mutationKey: [POST_USER_VALIDATION],
  });
}

export function usePostUserLogin() {
  return useMutation({
    mutationFn: (params: UserLoginType) => postUserLogin(params),
    mutationKey: [POST_USER_LOGIN],
  });
}

export function usePostVerificationCode() {
  return useMutation({
    mutationFn: (params: SendVerificationCodeType) => postVerificationCode(params),
    mutationKey: [POST_VERIFICATION_CODE],
  });
}

export function usePostValidateCode() {
  return useMutation({
    mutationFn: (params: ValidateCodeType) => postValidateCode(params),
    mutationKey: [POST_VALIDATE_CODE],
  });
}

export function usePostChangePassword() {
  return useMutation({
    mutationFn: (params: ChangePasswordType) => postChangePassword(params),
    mutationKey: [POST_CHANGE_PASSWORD],
  });
}

export function useGetRecaptchaStatus() {
  return useQuery({
    queryFn: () => getRecaptchaStatus(),
    queryKey: [GET_RECAPTCHA_STATUS],
  });
}

export function useGetGuestToken() {
  return useMutation({
    mutationFn: () => getGuestToken(),
    mutationKey: [GET_GUEST_TOKEN],
  });
}
