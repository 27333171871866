export enum FILTER_KEYS {
  CATEGORY = 'categories',
  MEDAL = 'types',
  VIEW_DEMO = 'search_demo',
  SELLABLE = 'sellable',
  IS_SUBSCRIPTION = 'subscription',
  ORIGINAL = 'original',
  FEATURED = 'featured',
  IRANIAN = 'iranian',
  LICENCED = 'licensed',
  SORT = 'sort_by',
  FIND = 'find',
  CATEGORY_SLUGS = 'category_slugs',
  TAG_SLUGS = 'tag_slugs',
  PAGE = 'page',
  HAS_DISCOUNT = 'hasDiscount',
}

export enum SORT_MODE {
  NEWEST = 'newest',
  TOP_SALE = 'top_sales',
  MOST_UPDATE = 'last_updated',
  SPECIAL_SALE = 'highest_discount',
}

export enum ARCHIVE_PAGE_TYPE {
  CATEGORY = 'category',
  TAG = 'tag',
  SEARCH = 'search',
}
