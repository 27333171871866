import { useEffect, useState } from 'react';
import OTPInput from 'react-otp-input';

import Container from '@/components/@base/container';
import { Input } from '@/components/@base/input';
import { Text } from '@/components/@base/typography';

import { pageLevelLocalization } from '@/constant/localization';
import Props from '@/components/app/auth/components/@shared/2fa/type'
const { login: loginLocalization } = pageLevelLocalization;

export default function TwoFactor({ handleInputChange, error }:Props) {
  const [twoFa, setTwoFa] = useState('');
  useEffect(() => {
    handleInputChange('twoFactor', twoFa);
  }, [twoFa]);

  return (
    <Container>
      <Text className='py-4 text-[16px] text-[#424244]'>
        {loginLocalization.enterTwoFactorCode}:
      </Text>
      <OTPInput
        value={twoFa}
        onChange={setTwoFa}
        numInputs={6}
        inputType="number"
        containerStyle={{ display: 'flex', gap: 12, direction: 'ltr' }}
        renderInput={(props) => <Input {...props} className='h-[48px] !w-[48px] text-2xl' />}
      />
      {error ? <div className='mt-1 text-sm text-red-600'>{error}</div> : null}
    </Container>
  );
}
