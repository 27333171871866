import cn from '@/lib/clsxm';

export default function Spinner({className}: {className?: string}) {
  const imageSrc = '/images/spinner/badge.svg' ;

  return (
    <div className={cn(" inset-0 z-[1000] flex justify-center bg-opacity-55 items-center w-full h-[200px]", className)}>
      <div
        className="relative w-[80px] h-[80px] inline-block bg-white  rounded-full shadow-2xl"

      >
        <div
          className="absolute -inset-3 rounded-full"
          style={{
            background: 'radial-gradient(rgba(255, 255, 255, 0.01), rgba(255, 255, 255, 0.5))',
            padding: '12px',
          }}
        ></div>
        <div
          className="absolute bg-spinner -inset-6 rounded-full  animate-spin-around"
        />
        <img
          src={imageSrc}
          alt="spinner"
          className="absolute inset-0 w-[40px] h-[40px] m-auto"

        />
      </div>
    </div>
  );
}



