import { AxiosError } from 'axios';
import { TypeOptions } from 'react-toastify/dist/types';

import { getCookie } from '@/lib/cookies';

import localization from '@/constant/localization';
import { API_GATE_WAY, STORAGE_URL } from '@/constant/routes';
import StorageKey from '@/constant/storage-key';

export function APIUrlGenerator(route: string, qry?: object): string {
  const query = qry || {};
  const queryKeys = Object.keys(query);
  let apiUrl = `${API_GATE_WAY}${route}`;

  queryKeys.map((item, index) => {
    if (index === 0) {
      apiUrl += '?';
    }
    if (queryKeys.length !== index + 1) {
      apiUrl += item + '=' + query[item] + '&';
    } else {
      apiUrl += item + '=' + query[item];
    }
  });
  return apiUrl;
}

export function APIErrorResponseGenerator(e: AxiosError<any, any>): {
  message: string;
  type: TypeOptions;
} {
  return {
    message: e?.response?.data?.errors?.[0]?.message || localization.somethingWentWrong,
    type: 'error',
  };
}

export const isMobileDevice = (userAgent) => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
};

export function imageBuilder(
  name: string,
  {
    ext = 'webp',
  }: {
    ext: string;
  } = { ext: 'webp' },
) {
  return `${STORAGE_URL}/images/${name}.${ext}`;
}

export function isLoggedIn() {
  return !!localStorage.getItem(StorageKey.TOKEN);
}

export function addZeroIfUnder10(number: number | string) {
  if (+number < 10) {
    return `0${number}`;
  }
  return number;
}

export function getFromLocalStorage(key: string): string | null {
  if (typeof window !== 'undefined' && window.localStorage) {
    return localStorage.getItem(key);
  }
  return null;
}

export function toPersianNumber(number: number): string {
  const persianDigits = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
  return number.toString().replace(/\d/g, (digit) => persianDigits[parseInt(digit)]);
}

export function priceFormatter(price?: number): string {
  if (!price || price === 0) {
    return localization.free;
  } else {
    let priceString: string = price.toString();
    let formattedPrice: string = '';
    let dotCounter: number = 0;

    for (let i = priceString.length - 1; i >= 0; i--) {
      formattedPrice = priceString[i] + formattedPrice;
      dotCounter++;

      if (dotCounter === 3 && i !== 0) {
        formattedPrice = '٬' + formattedPrice;
        dotCounter = 0;
      }
    }

    return formattedPrice;
  }
}

export function buildGuestToken() {
  const characters = '0123456789abcdefghijklmnopqrstuvwxyz';
  let result = 'guest_';
  const length = 34;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export function isGuestUser() {
  const guestToken = getCookie(StorageKey.TOKEN);
  if (guestToken?.includes('guest_')) {
    return true;
  }
  return false;
}

export const generateJsonLdFromData = (faqData, nameVar) => {
  const arrayRegex = /^\s*\[.*\]\s*$/;
  const defaultFaq = [
    {
      '@type': 'Question',
      name: `چگونه از سایت ژاکت، ${nameVar} را خریداری کنم؟`,
      acceptedAnswer: {
        '@type': 'Answer',
        text: `شما میتوانید با رفتن به صفحه ${nameVar}، محصول وردپرسی مورد نظر خود را انتخاب کرده و توضیحات تکمیلی ${nameVar} را مشاهده کنید و در صورت نیاز با کلیک بر روی دکمه افزودن به سبد خرید، ${nameVar} را برای سایت خود خریداری کنید`,
      },
    },
    {
      '@type': 'Question',
      name: `ویژگی‌های مهم ${nameVar} چیست؟`,
      acceptedAnswer: {
        '@type': 'Answer',
        text: `یکی از مهم‌ترین ویژگی‌های ${nameVar} ژاکت، اصالت و اورجینال بودن آن است. ویژگی بعدی محصولات ${nameVar} پشتیبانی ۶ ماهه ژاکت و توسعه‌دهنده محصول وردپرسی است که شما میتوانید تمامی سوالات خود را از توسعه‌دهندگان بپرسید.`,
      },
    },
    {
      '@type': 'Question',
      name: `چگونه میتوانیم ${nameVar} خریداری‌شده را نصب کنیم؟`,
      acceptedAnswer: {
        '@type': 'Answer',
        text: `شما میتوانید فایل ${nameVar} را از طریق پنل کاربری خود دانلود کنید، سپس از دو روش نصب از طریق پیشخوان وردپرس و یا استفاده از بسته‌نصبی آسان، ${nameVar} خود را به راحتی نصب کنید`,
      },
    },
    {
      '@type': 'Question',
      name: `چرا باید ${nameVar} را از ژاکت خریداری کنم؟`,
      acceptedAnswer: {
        '@type': 'Answer',
        text: `تمامی محصولات ${nameVar} ژاکت پس از بررسی توسط متخصص‌های ژاکت و همچنین تایید اصالت کالا از وبسایت‌های معتبر وردپرس، به فروش میرسند. همچنین پس از خریداری ${nameVar} توسعه‌دهندگان موظف هستند که به تمامی سوالات شما در رابطه با ${nameVar} در کوتاه‌ترین زمان پاسخ دهند. همچنین تمامی ${nameVar} پرطرفدار در بازه‌های زمانی کوتاه بروزرسانی می‌شوند و شما میتوانید به آخرین نسخه ${nameVar} دسترسی داشته باشید`,
      },
    },
  ];

  if (!faqData || !arrayRegex.test(faqData)) {
    faqData = defaultFaq;
  } else {
    try {
      faqData = JSON.parse(faqData);
      if (!Array.isArray(faqData) || faqData.length === 0) {
        faqData = defaultFaq;
      }
    } catch (error) {
      faqData = defaultFaq;
    }
  }

  const jsonLDData = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faqData.map((item) => ({
      '@type': 'Question',
      name: item.name,
      acceptedAnswer: {
        '@type': 'Answer',
        text: item.acceptedAnswer.text,
      },
    })),
  };

  return jsonLDData;
};
