import { create } from 'zustand';

export enum STEP {
  AUTH_STEP_START = 'validation',
  AUTH_STEP_SEND_CODE = 'otp',
  AUTH_STEP_SHOW_DASHBOARD = 'login',
  AUTH_STEP_GET_MOBILE = 'getMobile',
  AUTH_STEP_GET_EMAIL = 'getEmail',
  AUTH_STEP_PASSWORD_INPUT = 'password',
  AUTH_STEP_CONFIRM_PASSWORD_INPUT = 'confirmPassword',
}

interface AuthStepStore {
  step: STEP;
  phoneNumber: string;
  username: string;
  password: string;
  userNotExist: boolean;
  setUserNotExist: (userNotExist: boolean) => void;
  enterByPassword: boolean;
  twoFactor: boolean;
  recoveryMode: boolean;
  setPassword: (password: string) => void;
  setStep: (step: STEP) => void;
  setPhoneNumber: (phoneNumber: string) => void;
  setUsername: (username: string) => void;
  shouldEnterUsername: boolean;
  setShouldEnterUsername: (shouldEnterUsername: boolean) => void;
  setEnterByPassword: (enterByPassword: boolean) => void;
  setRecoveryMode: (recoveryMode: boolean) => void;
  setTwoFactor: (twoFactor: boolean) => void;
}

export const useAuthStepStore = create<AuthStepStore>((set) => ({
  step: STEP.AUTH_STEP_START,
  phoneNumber: '',
  username: '',
  password: '',
  shouldEnterUsername: false,
  userNotExist: false,
  enterByPassword: false,
  recoveryMode: false,
  twoFactor: false,
  setStep: (step) => set({ step }),
  setPhoneNumber: (phoneNumber) => set({ phoneNumber }),
  setUsername: (username) => set({ username }),
  setPassword: (password) => set({ password }),
  setUserNotExist: (userNotExist) => set({ userNotExist }),
  setShouldEnterUsername: (shouldEnterUsername) => set({ shouldEnterUsername }),
  setEnterByPassword: (enterByPassword) => set({ enterByPassword }),
  setRecoveryMode: (recoveryMode) => set({ recoveryMode }),
  setTwoFactor: (twoFactor) => set({ twoFactor }),
}));
