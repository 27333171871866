'use client';
import { LuRefreshCcw } from 'react-icons/lu';

import cn from '@/lib/clsxm';

import Container from '@/components/@base/container';
import { Text } from '@/components/@base/typography';
import { useOtpTimer } from '@/components/app/auth/helpers';

import { pageLevelLocalization } from '@/constant/localization';

import Props from '@/components/app/auth/components/@shared/resend-otp/type';

const { login: loginLocalization } = pageLevelLocalization;

export default function ResendOtp({ initialSeconds = 120, handleSendCode }:Props) {
  const { showTimer, handleResendClick, formatTime } = useOtpTimer(
    initialSeconds,
    handleSendCode,
  );

  return (
    <Container
      center
      className={cn(
        'w-[110px] cursor-pointer justify-start gap-1 border-b border-dashed border-[#6097F3] text-[#6097F3]',
        showTimer && 'w-[70px] cursor-not-allowed border-[#A4A6BA] text-[#A4A6BA]',
      )}
      onClick={handleResendClick}
    >
      <LuRefreshCcw />
      <Text>
        {!showTimer && loginLocalization.resendCode}
        {showTimer && formatTime()}
      </Text>
    </Container>
  );
}
