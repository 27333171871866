import axios from '@/lib/axios-instance';
import { APIUrlGenerator } from '@/lib/helper';

import {
  ChangePasswordType,
  CredentialValidationAuth,
  GuestTokenType,
  RecaptchaType,
  SendVerificationCodeType,
  UserAuthenticationType,
  UserLoginType,
  UserValidationType,
  ValidateCodeType,
} from '@/components/app/auth/services/types';

import { API_ROUTES } from '@/constant/routes';

export async function postUserValidation(params: UserValidationType) {
  const {
    data: { payload },
  }: { data: { payload: UserAuthenticationType } } = await axios.post(
    APIUrlGenerator(API_ROUTES.POST_USER_VALIDATION),
    params,
  );
  return payload;
}

export async function postUserLogin(params: UserLoginType) {
  const {
    data: { payload },
  }: { data: { payload: CredentialValidationAuth } } = await axios.post(
    APIUrlGenerator(API_ROUTES.POST_USER_LOGIN),
    params,
  );
  return payload;
}

export async function postVerificationCode(params: SendVerificationCodeType) {
  const {
    data: { payload },
  }: { data: { payload: UserAuthenticationType } } = await axios.post(
    APIUrlGenerator(API_ROUTES.POST_VERIFICATION_CODE),
    params,
  );
  return payload;
}

export async function postValidateCode(params: ValidateCodeType) {
  const {
    data: { payload },
  }: { data: { payload: CredentialValidationAuth } } = await axios.post(
    APIUrlGenerator(API_ROUTES.POST_VALIDATE_CODE),
    params,
  );
  return payload;
}

export async function postChangePassword(params: ChangePasswordType) {
  const {
    data: { payload },
  }: { data: { payload: UserAuthenticationType } } = await axios.post(
    APIUrlGenerator(API_ROUTES.POST_CHANGE_PASSWORD),
    params,
  );
  return payload;
}

export async function getRecaptchaStatus() {
  const {
    data: { payload },
  }: { data: { payload: RecaptchaType } } = await axios.get(
    APIUrlGenerator(API_ROUTES.GET_RECAPTCHA_STATUS),
  );
  return payload;
}

export async function getGuestToken() {
  const {
    data: { payload },
  }: { data: { payload: GuestTokenType } } = await axios.get(
    APIUrlGenerator(API_ROUTES.GET_GUEST_TOKEN),
  );
  return payload;
}
