'use client';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import Container from '@/components/@base/container';
import { showToast } from '@/components/@base/toast/show-toast';
import TwoFactor from '@/components/app/auth/components/@shared/2fa';
import AuthForm from '@/components/app/auth/components/@shared/auth-form';
import LoginOptions from '@/components/app/auth/components/@shared/login-options';
import { LOGIN_BY } from '@/components/app/auth/components/@shared/login-options/type';
import PasswordInput from '@/components/app/auth/components/@shared/password-input';
import RecoveryLink from '@/components/app/auth/components/@shared/recovery-link';
import Props from '@/components/app/auth/components/password/type';

import { STEP, useAuthStepStore } from '@/store/auth.store';

import { pageLevelLocalization } from '@/constant/localization';

const { login: loginLocalization } = pageLevelLocalization;
export const PostSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
});

export default function Password({ onSubmit, isLoading }: Props) {
  const { step, userNotExist, setStep, recoveryMode, twoFactor } = useAuthStepStore();
  const confirmPass = step === STEP.AUTH_STEP_CONFIRM_PASSWORD_INPUT;
  const backHandler = () => {
    if (recoveryMode && !confirmPass) {
      setStep(STEP.AUTH_STEP_START);
    } else if (confirmPass) {
      setStep(STEP.AUTH_STEP_PASSWORD_INPUT);
    } else {
      setStep(STEP.AUTH_STEP_START);
    }
  };
  const customValidate = (values) => {
    if (values.password && values.password.length < 6) {
      showToast({
        message: loginLocalization.passwordShouldBeAtleastSixChar,
        type: 'error',
      });
    }
  };
  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        password: '',
        confirmPassword: '',
        twoFactor: '',
      }}
      onSubmit={(values, formikHelpers) => {
        const { password, confirmPassword, twoFactor } = values;
        if (confirmPassword) {
          onSubmit(password, confirmPassword);
        }
        if ((recoveryMode || userNotExist) && !confirmPassword) {
          setStep(STEP.AUTH_STEP_CONFIRM_PASSWORD_INPUT);
        } else if (!confirmPassword) {
          onSubmit(password, '', twoFactor);
        }
      }}
      validationSchema={PostSchema}
      validate={customValidate}
    >
      {({ values, handleChange, setFieldValue }) => {
        return (
          <Form>
            <AuthForm
              title={
                recoveryMode && !confirmPass
                  ? loginLocalization.enterYourNewPassword
                  : confirmPass
                    ? loginLocalization.enterPasswordAgain
                    : loginLocalization.enterPassword
              }
              backHandler={backHandler}
              submitText={loginLocalization.login}
              isLoading={isLoading}
              actions={
                <Container center className='items-end justify-between pt-5'>
                  {!userNotExist && <RecoveryLink />}
                  <LoginOptions loginBy={LOGIN_BY.CODE} />
                </Container>
              }
            >
              <PasswordInput
                confirmPass={confirmPass}
                value={confirmPass ? values?.confirmPassword : values?.password}
                handleInputChange={handleChange}
              />
              {twoFactor && !userNotExist && <TwoFactor handleInputChange={setFieldValue} />}
            </AuthForm>
          </Form>
        );
      }}
    </Formik>
  );
}
