export const GET_USER = 'get-user';
export const GET_SERVICE_LIST = 'get-item-list';

export const GET_STORY_LIST = 'get-story-list';
export const GET_ADS_BANNER = 'get-ads-banner';
export const GET_BUNDLE_LIST = 'get-bundle-list';
export const GET_NEWEST_PRODUCT_LIST = 'get-newest-product-list';
export const GET_PRODUCTS_BANNER_LIST = 'get-products-banner-list';
export const GET_BEST_SELLER = 'get-best-seller';
export const GET_NEWEST_IRANIAN_PRODUCT_LIST = 'get-newest-iranian-product-list';
export const GET_ON_SALE_PRODUCT_LIST = 'get-on-sale-product-list';
export const GET_DEMO_LIST = 'get-demo-list';
export const GET_BEST_PRODUCT_LIST = 'get-best-product-list';
export const GET_BLOG_LIST = 'get-blog-list';
export const GET_COURSE_LIST = 'get-course-list';
export const GET_BEST_OF_CATE_LIST = 'get-best-of-cate-list';
export const GET_IR_BEST_LIST = 'get-ir-best-list';
export const GET_ARCHIVE_INFO = 'get-archive-info';
export const GET_SUGGESTION_DEMOS_LIST = 'get-suggestion-demos-list';
export const GET_PRODUCT_LIST = 'get-product-list';
export const GET_FILTER_CATEGORY_LIST = 'get-filter-category-list';
export const GET_ARCHIVE_ADS_BANNER = 'get-archive-ads-banner';
export const GET_SEARCH_SUGGESTION = 'get-search-suggestion';
export const GET_MENU_LIST = 'get-menu-list';
export const GET_SINGLE_PRODUCT = 'get-single-product';
export const GET_COMMENT_LIST = 'get-comment-list';
export const GET_ADD_TO_CART_INFO = 'get-add-to-cart-info';
export const GET_DOWNLOAD_LINK = 'get-download-link';
export const POST_COMMENT = 'post-comment';
export const POST_FAVORITES = 'post-favorites';
export const POST_RATE = 'post-rate';
export const POST_FEEDBACK = 'post-feedback';
export const GET_ACCEPT_AFFILIATE_RULES = 'accept-affiliate-rules';
export const POST_USER_VALIDATION = 'user-validation';
export const POST_USER_LOGIN = 'user-login';
export const POST_VERIFICATION_CODE = 'post-verification-code';
export const POST_VALIDATE_CODE = 'post-verification-code';
export const POST_CHANGE_PASSWORD = 'post-change-password';
export const GET_RECAPTCHA_STATUS = 'get-recaptcha-status';
export const GET_GUEST_TOKEN = 'get-guest-token';
export const GET_PROFILE = 'get-profile';
export const GET_SEARCH = 'get-search';
export const GET_FREE_DOWNLOAD_LINK = 'get-free-download-link';
export const GET_CART = 'get-cart';
export const DELETE_FROM_CART = 'delete-from-cart';
export const GET_STORE_LIST = 'get-store-list';
export const POST_VOUCHER = 'post-voucher';
export const DELETE_VOUCHER = 'delete-voucher';
export const POST_CHECKOUT = 'post-checkout';
export const GET_PRODUCT_DEMO = 'get-product-demo';
export const POST_NEWSLETTER = 'post-newsletter';
export const GET_SIDE_IN = 'get-sid-in';
export const GET_DYNAMIC_PAGES_LIST = 'get-dynamic-pages-list';
export const GET_DYNAMIC_PAGE_CONTENT = 'get-dynamic-page-content';
export const GET_TOPBAR = 'get-topbar';
export const GET_PRODUCT_RATING = 'get-product-rating';
export const GET_USER_IP = 'get-user-ip';
export const GET_STORE_DISCOUNT = 'get-store-discount';
