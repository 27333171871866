import Cookies, { CookieSetOptions } from 'universal-cookie';
import StorageKey from '@/constant/storage-key';

const cookies = new Cookies(null, {
  path: '/',
  domain: process.env.NEXT_PUBLIC_WEB_TOKEN_DOMAIN,
});
export const getCookie = (name: string) => {
  return cookies.get(name);
};
export const setCookie = (name: string, val: any, options?: CookieSetOptions) => {
  return cookies.set(name, val, options);
};
export const removeCookie = (name: string) => {
  return cookies.remove(name)
};
