export const codeList = [
  {
    code: '+98',
    slug: 'IR',
  },
  {
    code: '+1',
    slug: 'US - CA',
  },
  {
    code: '+7',
    slug: 'RU - KZ',
  },
  {
    code: '+20',
    slug: 'EG',
  },
  {
    code: '+27',
    slug: 'ZA',
  },
  {
    code: '+30',
    slug: 'GR',
  },
  {
    code: '+31',
    slug: 'NL',
  },
  {
    code: '+32',
    slug: 'BE',
  },
  {
    code: '+33',
    slug: 'FR',
  },
  {
    code: '+34',
    slug: 'ES',
  },
  {
    code: '+36',
    slug: 'HU',
  },
  {
    code: '+39',
    slug: 'IT',
  },
  {
    code: '+40',
    slug: 'RO',
  },
  {
    code: '+41',
    slug: 'CH',
  },
  {
    code: '+43',
    slug: 'AT',
  },
  {
    code: '+44',
    slug: 'GB',
  },
  {
    code: '+45',
    slug: 'DK',
  },
  {
    code: '+46',
    slug: 'SE',
  },
  {
    code: '+47',
    slug: 'NO',
  },
  {
    code: '+47',
    slug: 'SJ',
  },
  {
    code: '+48',
    slug: 'PL',
  },
  {
    code: '+49',
    slug: 'DE',
  },
  {
    code: '+51',
    slug: 'PE',
  },
  {
    code: '+52',
    slug: 'MX',
  },
  {
    code: '+53',
    slug: 'CU',
  },
  {
    code: '+54',
    slug: 'AR',
  },
  {
    code: '+55',
    slug: 'BR',
  },
  {
    code: '+56',
    slug: 'CL',
  },
  {
    code: '+57',
    slug: 'CO',
  },
  {
    code: '+58',
    slug: 'VE',
  },
  {
    code: '+60',
    slug: 'MY',
  },
  {
    code: '+61',
    slug: 'AU',
  },
  {
    code: '+61',
    slug: 'CC',
  },
  {
    code: '+61',
    slug: 'CX',
  },
  {
    code: '+62',
    slug: 'ID',
  },
  {
    code: '+63',
    slug: 'PH',
  },
  {
    code: '+64',
    slug: 'NZ',
  },
  {
    code: '+64',
    slug: 'PN',
  },
  {
    code: '+65',
    slug: 'SG',
  },
  {
    code: '+66',
    slug: 'TH',
  },
  {
    code: '+81',
    slug: 'JP',
  },
  {
    code: '+82',
    slug: 'KR',
  },
  {
    code: '+84',
    slug: 'VN',
  },
  {
    code: '+86',
    slug: 'CN',
  },
  {
    code: '+90',
    slug: 'TR',
  },
  {
    code: '+91',
    slug: 'IN',
  },
  {
    code: '+92',
    slug: 'PK',
  },
  {
    code: '+93',
    slug: 'AF',
  },
  {
    code: '+94',
    slug: 'LK',
  },
  {
    code: '+95',
    slug: 'MM',
  },

  {
    code: '+211',
    slug: 'SS',
  },
  {
    code: '+212',
    slug: 'EH',
  },
  {
    code: '+212',
    slug: 'MA',
  },
  {
    code: '+213',
    slug: 'DZ',
  },
  {
    code: '+216',
    slug: 'TN',
  },
  {
    code: '+218',
    slug: 'LY',
  },
  {
    code: '+220',
    slug: 'GM',
  },
  {
    code: '+221',
    slug: 'SN',
  },
  {
    code: '+222',
    slug: 'MR',
  },
  {
    code: '+223',
    slug: 'ML',
  },
  {
    code: '+224',
    slug: 'GN',
  },
  {
    code: '+225',
    slug: 'CI',
  },
  {
    code: '+226',
    slug: 'BF',
  },
  {
    code: '+227',
    slug: 'NE',
  },
  {
    code: '+228',
    slug: 'TG',
  },
  {
    code: '+229',
    slug: 'BJ',
  },
  {
    code: '+230',
    slug: 'MU',
  },
  {
    code: '+231',
    slug: 'LR',
  },
  {
    code: '+232',
    slug: 'SL',
  },
  {
    code: '+233',
    slug: 'GH',
  },
  {
    code: '+234',
    slug: 'NG',
  },
  {
    code: '+235',
    slug: 'TD',
  },
  {
    code: '+236',
    slug: 'CF',
  },
  {
    code: '+237',
    slug: 'CM',
  },
  {
    code: '+238',
    slug: 'CV',
  },
  {
    code: '+239',
    slug: 'ST',
  },
  {
    code: '+240',
    slug: 'GQ',
  },
  {
    code: '+241',
    slug: 'GA',
  },
  {
    code: '+242',
    slug: 'CG',
  },
  {
    code: '+243',
    slug: 'CD',
  },
  {
    code: '+244',
    slug: 'AO',
  },
  {
    code: '+245',
    slug: 'GW',
  },
  {
    code: '+246',
    slug: 'IO',
  },
  {
    code: '+248',
    slug: 'SC',
  },
  {
    code: '+249',
    slug: 'SD',
  },
  {
    code: '+250',
    slug: 'RW',
  },
  {
    code: '+251',
    slug: 'ET',
  },
  {
    code: '+252',
    slug: 'SO',
  },
  {
    code: '+253',
    slug: 'DJ',
  },
  {
    code: '+254',
    slug: 'KE',
  },
  {
    code: '+255',
    slug: 'TZ',
  },
  {
    code: '+256',
    slug: 'UG',
  },
  {
    code: '+257',
    slug: 'BI',
  },
  {
    code: '+258',
    slug: 'MZ',
  },
  {
    code: '+260',
    slug: 'ZM',
  },
  {
    code: '+261',
    slug: 'MG',
  },
  {
    code: '+262',
    slug: 'RE',
  },
  {
    code: '+262',
    slug: 'YT',
  },
  {
    code: '+263',
    slug: 'ZW',
  },
  {
    code: '+264',
    slug: 'NA',
  },
  {
    code: '+265',
    slug: 'MW',
  },
  {
    code: '+266',
    slug: 'LS',
  },
  {
    code: '+267',
    slug: 'BW',
  },
  {
    code: '+268',
    slug: 'SZ',
  },
  {
    code: '+269',
    slug: 'KM',
  },
  {
    code: '+290',
    slug: 'SH',
  },
  {
    code: '+291',
    slug: 'ER',
  },
  {
    code: '+297',
    slug: 'AW',
  },
  {
    code: '+298',
    slug: 'FO',
  },
  {
    code: '+299',
    slug: 'GL',
  },
  {
    code: '+350',
    slug: 'GI',
  },
  {
    code: '+351',
    slug: 'PT',
  },
  {
    code: '+352',
    slug: 'LU',
  },
  {
    code: '+353',
    slug: 'IE',
  },
  {
    code: '+354',
    slug: 'IS',
  },
  {
    code: '+355',
    slug: 'AL',
  },
  {
    code: '+356',
    slug: 'MT',
  },
  {
    code: '+357',
    slug: 'CY',
  },
  {
    code: '+358',
    slug: 'FI',
  },
  {
    code: '+359',
    slug: 'BG',
  },
  {
    code: '+370',
    slug: 'LT',
  },
  {
    code: '+371',
    slug: 'LV',
  },
  {
    code: '+372',
    slug: 'EE',
  },
  {
    code: '+373',
    slug: 'MD',
  },
  {
    code: '+374',
    slug: 'AM',
  },
  {
    code: '+375',
    slug: 'BY',
  },
  {
    code: '+376',
    slug: 'AD',
  },
  {
    code: '+377',
    slug: 'MC',
  },
  {
    code: '+378',
    slug: 'SM',
  },
  {
    code: '+379',
    slug: 'VA',
  },
  {
    code: '+380',
    slug: 'UA',
  },
  {
    code: '+381',
    slug: 'RS',
  },
  {
    code: '+382',
    slug: 'ME',
  },
  {
    code: '+383',
    slug: 'XK',
  },
  {
    code: '+385',
    slug: 'HR',
  },
  {
    code: '+386',
    slug: 'SI',
  },
  {
    code: '+387',
    slug: 'BA',
  },
  {
    code: '+389',
    slug: 'MK',
  },
  {
    code: '+420',
    slug: 'CZ',
  },
  {
    code: '+421',
    slug: 'SK',
  },
  {
    code: '+423',
    slug: 'LI',
  },
  {
    code: '+500',
    slug: 'FK',
  },
  {
    code: '+501',
    slug: 'BZ',
  },
  {
    code: '+502',
    slug: 'GT',
  },
  {
    code: '+503',
    slug: 'SV',
  },
  {
    code: '+504',
    slug: 'HN',
  },
  {
    code: '+505',
    slug: 'NI',
  },
  {
    code: '+506',
    slug: 'CR',
  },
  {
    code: '+507',
    slug: 'PA',
  },
  {
    code: '+508',
    slug: 'PM',
  },
  {
    code: '+509',
    slug: 'HT',
  },
  {
    code: '+590',
    slug: 'BL',
  },
  {
    code: '+590',
    slug: 'MF',
  },
  {
    code: '+591',
    slug: 'BO',
  },
  {
    code: '+592',
    slug: 'GY',
  },
  {
    code: '+593',
    slug: 'EC',
  },
  {
    code: '+595',
    slug: 'PY',
  },
  {
    code: '+597',
    slug: 'SR',
  },
  {
    code: '+598',
    slug: 'UY',
  },
  {
    code: '+599',
    slug: 'AN',
  },
  {
    code: '+599',
    slug: 'CW',
  },
  {
    code: '+670',
    slug: 'TL',
  },
  {
    code: '+672',
    slug: 'AQ',
  },
  {
    code: '+673',
    slug: 'BN',
  },
  {
    code: '+674',
    slug: 'NR',
  },
  {
    code: '+675',
    slug: 'PG',
  },
  {
    code: '+676',
    slug: 'TO',
  },
  {
    code: '+677',
    slug: 'SB',
  },
  {
    code: '+678',
    slug: 'VU',
  },
  {
    code: '+679',
    slug: 'FJ',
  },
  {
    code: '+680',
    slug: 'PW',
  },
  {
    code: '+681',
    slug: 'WF',
  },
  {
    code: '+682',
    slug: 'CK',
  },
  {
    code: '+683',
    slug: 'NU',
  },
  {
    code: '+685',
    slug: 'WS',
  },
  {
    code: '+686',
    slug: 'KI',
  },
  {
    code: '+687',
    slug: 'NC',
  },
  {
    code: '+688',
    slug: 'TV',
  },
  {
    code: '+689',
    slug: 'PF',
  },
  {
    code: '+690',
    slug: 'TK',
  },
  {
    code: '+691',
    slug: 'FM',
  },
  {
    code: '+692',
    slug: 'MH',
  },
  {
    code: '+850',
    slug: 'KP',
  },
  {
    code: '+852',
    slug: 'HK',
  },
  {
    code: '+853',
    slug: 'MO',
  },
  {
    code: '+855',
    slug: 'KH',
  },
  {
    code: '+856',
    slug: 'LA',
  },
  {
    code: '+880',
    slug: 'BD',
  },
  {
    code: '+886',
    slug: 'TW',
  },
  {
    code: '+960',
    slug: 'MV',
  },
  {
    code: '+961',
    slug: 'LB',
  },
  {
    code: '+962',
    slug: 'JO',
  },
  {
    code: '+963',
    slug: 'SY',
  },
  {
    code: '+964',
    slug: 'IQ',
  },
  {
    code: '+965',
    slug: 'KW',
  },
  {
    code: '+966',
    slug: 'SA',
  },
  {
    code: '+967',
    slug: 'YE',
  },
  {
    code: '+968',
    slug: 'OM',
  },
  {
    code: '+970',
    slug: 'PS',
  },
  {
    code: '+971',
    slug: 'AE',
  },
  {
    code: '+972',
    slug: 'IL',
  },
  {
    code: '+973',
    slug: 'BH',
  },
  {
    code: '+974',
    slug: 'QA',
  },
  {
    code: '+975',
    slug: 'BT',
  },
  {
    code: '+976',
    slug: 'MN',
  },
  {
    code: '+977',
    slug: 'NP',
  },
  {
    code: '+992',
    slug: 'TJ',
  },
  {
    code: '+993',
    slug: 'TM',
  },
  {
    code: '+994',
    slug: 'AZ',
  },
  {
    code: '+995',
    slug: 'GE',
  },
  {
    code: '+996',
    slug: 'KG',
  },
  {
    code: '+998',
    slug: 'UZ',
  },
];
