import { useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { IoIosArrowDown } from 'react-icons/io';

import cn from '@/lib/clsxm';

import Container from '@/components/@base/container';
import Combobox, {
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '@/components/@base/input/combobox';
import { Text } from '@/components/@base/typography';
import { codeList } from '@/components/app/auth/components/login/country-code/code-list';

export default function CountryCode() {
  const [query, setQuery] = useState('');
  const [selected, setSelected] = useState(codeList.find((item) => item.code === '+98'));

  const filteredCountries =
    query === ''
      ? codeList
      : codeList.filter(
          (country) =>
            country.slug.toLowerCase().includes(query.toLowerCase()) ||
            country.code.toString().includes(query),
        );

  return (
    <Container center className='absolute left-2 h-[44px] w-[100px]'>
      <Combobox value={selected} onChange={(value: { code: string, slug: string }) => setSelected(value)}>
        <Container dir='ltr' className='relative'>
          <ComboboxInput
            className={cn(
              'h-[44px] w-full rounded-md border border-[#EEF0F4] bg-[#F9FAFC] pr-8 text-right text-[#76767C] !outline-none transition-all duration-100',
              'focus:border-[#FFC87B]',
            )}
            displayValue={(country:{slug:string,code:string}) => country?.code}
            onChange={(event) => setQuery(event.target.value)}
          />
          <ComboboxButton className='group absolute inset-y-0 right-0 px-2.5'>
            <IoIosArrowDown className='size-4 fill-gray-500 group-hover:fill-gray-700' />
          </ComboboxButton>
          {selected && (
            <ReactCountryFlag
              countryCode={selected?.slug?.slice(0, 2)}
              svg
              style={{
                position: 'absolute',
                left: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                width: '24px',
                height: '24px',
              }}
            />
          )}
        </Container>
        <ComboboxOptions className='no-scrollbar absolute mt-1 h-[250px] w-[150px] overflow-auto rounded-md bg-white p-3 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
          {filteredCountries.map((country, index) => (
            <ComboboxOption
              key={index}
              value={country.code}
              className='flex cursor-pointer flex-row-reverse p-2 pl-3'
            >
              <Container center className='gap-1'>
                <Text className='truncate'>{country.slug}</Text>
                <Text className='ml-2 text-gray-500'>{country.code}</Text>
                <ReactCountryFlag
                  countryCode={country?.slug?.slice(0, 2)}
                  svg
                  style={{
                    width: '24px',
                    height: '24px',
                  }}
                />
              </Container>
            </ComboboxOption>
          ))}
        </ComboboxOptions>
      </Combobox>
    </Container>
  );
}
