export const API_GATE_WAY = process.env.NEXT_PUBLIC_API_URL;
export const DASHBOARD_BASE_URL = process.env.NEXT_PUBLIC_DASHBOARD_BASE_URL;
export const SITE_URL = process.env.NEXT_PUBLIC_MAIN_URL;
export const STORAGE_URL = '';
export const API_ROUTES = {
  GET_STORY_LIST: '/v2/stories',
  GET_ADS_BANNER: (type: string) =>
    `/v2/site-bars/advertisements/product-banner/banner/${type}`,
  GET_BUNDLE_LIST: '/v2/public/products/top-bundles',
  GET_NEWEST_PRODUCT_LIST: '/v2/public/products/latest',
  GET_BEST_SELLER: '/v2/public/users/best-seller',
  GET_BLOG_LIST: '/blog/posts',
  GET_BEST_OF_CATE_LIST: '/v2/public/products/best-of-category',
  GET_BEST_PRODUCT_LIST: '/v2/public/products/best-product',
  GET_IR_BEST_LIST: '/v2/public/products/top-sales',
  GET_DEMO_LIST: '/v2/public/products/top-demos',
  GET_CATEGORY_INFO: (slug: string) => `/v2/categories/${slug}`,
  GET_TAG_INFO: (slug: string) => `/v2/tags/${slug}`,
  GET_PRODUCT_LIST: `/v2/public/products/search`,
  GET_SUGGESTION_DEMOS_LIST: `/v2/public/products/search-demo`,
  GET_FILTER_CATEGORY_LIST: '/v2/categories',
  GET_SEARCH_SUGGESTION: '/v2/suggestion-search',
  GET_MENU_LIST: '/v2/menus',
  GET_ARCHIVE_ADS_BANNER: (categoryId: string) => `/v2/sidebar-banner?category=${categoryId}`,
  GET_SINGLE_PRODUCT: (slug: string) => `/v2/public/products/${slug}`,
  GET_COMMENT_LIST: (slug: string) => `/v2/public/products/${slug}/comments`,
  GET_ADD_ONS_INFO: (slug: string) => `/v2/cart/addons/${slug}`,
  GET_DOWNLOAD_LINK: (id: string) => `/v2/products/signer/${id}`,
  POST_COMMENT: '/v2/product-comment',
  POST_FAVORITES: '/v2/profile/favorites',
  POST_FEEDBACK: '/product-feedback',
  POST_RATE: '/v2/rate/product',
  POST_USER_VALIDATION: '/v2/auth/start',
  POST_USER_LOGIN: '/v2/auth/login',
  POST_VERIFICATION_CODE: '/v2/auth/send-code',
  POST_VALIDATE_CODE: '/v2/auth/validate-code',
  POST_CHANGE_PASSWORD: '/v2/auth/change-password',
  GET_RECAPTCHA_STATUS: '/v2/auth/recaptcha-status',
  GET_GUEST_TOKEN: '/token/guest',
  GET_PROFILE: '/v2/profile',
  GET_SEARCH: '/v2/public/products/search',
  GET_ICONIC_PRODUCTS: '/v2/public/products/search-alt',
  GET_FREE_DOWNLOAD_LINK: (productId: string) => `/product/download-free/${productId}`,
  GET_CART: '/v2/cart',
  POST_ADD_TO_CART: '/v2/cart/add',
  GET_STORE_LIST: (store: string) => `/v2/stores/${store}`,
  GET_STORE_DISCOUNT: (slug: string) => `/v2/stores/${slug}/discount`,
  DELETE_FROM_CART: (id: string) => `/v2/cart/${id}`,
  POST_VOUCHER: '/v2/cart/voucher-add',
  DELETE_VOUCHER: (code) => `/v2/cart/voucher-remove?code=${code}`,
  POST_CHECKOUT: '/billing/cart/checkout',
  POST_NEWSLETTER: '/v2/newsletter',
  GET_ACCEPT_AFFILIATE_RULES: '/v2/profile/accept-rules',
  GET_SIDE_IN: '/v2/public/users/sidein',
  GET_SETTINGS: (key: string) => `/v2/public/settings/${key}`,
  GET_PRODUCT_RATING: '/v2/rate/reminder',
  GET_USER_IP: `/cookie-permission`,
  LOG_OUT:"/v2/auth/logout"
};

export const HOME_PAGE = '/';
export const ACCOUNT = '/account/';
export const ABOUT_US = '/about/';
export const CONTACT = '/contact/';
export const BLOG = '/blog/';

export const storePath = {
  STORE: (store: string) => `/store/web/${store}`,
  STORE_PRODUCT: (store: string) => `/store/web/${store}/products`,
  STORE_INTRO: (store: string) => `/store/web/${store}/intro`,
  STORE_DEVELOPER_DISCOUNT: (store: string) => `/store/web/${store}/developer-discount-codes`,
} as const;
