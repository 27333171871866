const ICON_SIZE = {
  xss: 12,
  xs: 14,
  sm: 16,
  md: 20,
  lg: 24,
  xl: 32,
} as const;

export const ICON_COLOR = {
  white: '#fff',
  black: '#222',
  gray: '#878F9B',
  smoke: '#C1C3C8',
  orange: '#FF9606',
  red: '#FF6437',
  light_blue: '#CCE4FF',
  primary: '#6097F3',
  text_blue: '#3058BC',
  purple: '#7F84F7',
  secondary: '#00BC3B',
} as const;

export default ICON_SIZE;
