import { Form, Formik } from 'formik';
import { useEffect, useRef } from 'react';
import { RxPerson } from 'react-icons/rx';
import LoginOptions from 'src/components/app/auth/components/@shared/login-options';
import * as Yup from 'yup';

import Container from '@/components/@base/container';
import { Input } from '@/components/@base/input';
import AuthForm from '@/components/app/auth/components/@shared/auth-form';
import { LOGIN_BY } from '@/components/app/auth/components/@shared/login-options/type';
import RecoveryLink from '@/components/app/auth/components/@shared/recovery-link';
import CountryCode from '@/components/app/auth/components/login/country-code';
import Terms from '@/components/app/auth/components/login/terms';
import { useGetRecaptchaStatus } from '@/components/app/auth/services/hooks';
import Captcha from '@/components/shared/components/recaptcha';

import { STEP, useAuthStepStore } from '@/store/auth.store';

import ICON_SIZE, { ICON_COLOR } from '@/constant/icon-size-color';
import { pageLevelLocalization } from '@/constant/localization';

import Props from '@/components/app/auth/components/login/type';
type CaptchaRefType = {
  reset: () => void;
};
const { login: loginLocalization } = pageLevelLocalization;

export default function Login({ onSubmit, isLoading }:Props) {
  const captchaRef = useRef<CaptchaRefType | null>(null);
  const {
    phoneNumber,
    setPhoneNumber,
    setUsername,
    shouldEnterUsername,
    recoveryMode,
    username,
    enterByPassword,
    step,
    setStep,
  } = useAuthStepStore();
  const { data: recaptchaData } = useGetRecaptchaStatus();
  const handleInputChange = (e, setFieldValue) => {
    const { value } = e.target;
    if (/^(?:(?:\+98|0098|0)9\d{0,9})$/.test(value)) {
      setPhoneNumber(value);
      setUsername('');
      setFieldValue('username', value);
    } else {
      setUsername(value);
      setPhoneNumber('');
      setFieldValue('username', value);
    }
  };

  useEffect(() => {
    if ([STEP.AUTH_STEP_GET_MOBILE, STEP.AUTH_STEP_GET_EMAIL].includes(step)) {
      setPhoneNumber('');
      setUsername('');
    }
  }, [step]);

  const formContext = {
    title: getTitle(),
    placeholder: getPlaceholder(),
    submitText: getButtonTitle(),
    backLink: backHandler,
  };

  function getTitle() {
    switch (step) {
      case STEP.AUTH_STEP_GET_MOBILE:
        return loginLocalization.enterPhone;
      case STEP.AUTH_STEP_GET_EMAIL:
        return loginLocalization.enterEmail;
      default:
        return recoveryMode || shouldEnterUsername
          ? loginLocalization.enterPhoneNumberOrEmail
          : loginLocalization.loginAndRegister;
    }
  }

  function getButtonTitle() {
    if (recoveryMode) {
      return loginLocalization.sendVerificationCode;
    } else {
      return loginLocalization.loginOrRegister;
    }
  }

  function getPlaceholder() {
    switch (step) {
      case STEP.AUTH_STEP_GET_MOBILE:
        return loginLocalization.phone;
      case STEP.AUTH_STEP_GET_EMAIL:
        return loginLocalization.email;
      default:
        return loginLocalization.emailOrPhone;
    }
  }

  function backHandler() {
    if ([STEP.AUTH_STEP_GET_MOBILE, STEP.AUTH_STEP_GET_EMAIL].includes(step)) {
      setStep(STEP.AUTH_STEP_START);
    }
  }

  const validationSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    // recaptcha: Yup.string().required('Recaptcha is required'),
  });

  return (
    <Formik
      initialValues={{
        username: phoneNumber || username,
        recaptcha: null ,
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const recaptchaToken = values.recaptcha || '';
        onSubmit(recaptchaToken, () => {
          if (captchaRef.current) {
            captchaRef.current.reset();
          }
        });
      }}
    >
      {({ values, handleChange, setFieldValue }) => (
        <Form>
          <AuthForm
            title={formContext.title}
            submitText={formContext.submitText}
            isLoading={isLoading}
            backHandler={formContext.backLink}
            hideBackButton={step === STEP.AUTH_STEP_START}
            actions={
              <Container center className='items-end justify-between pt-5'>
                <Terms />
                {!shouldEnterUsername &&
                  (username ? (
                    <RecoveryLink />
                  ) : (
                    <LoginOptions
                      recaptchaToken={values.recaptcha}
                      loginBy={enterByPassword ? LOGIN_BY.CODE : LOGIN_BY.PASSWORD}
                    />
                  ))}
              </Container>
            }
          >
            <Input
              id='username'
              className='group border-0 pr-3 text-[15px] text-[#76767C] placeholder-[#76767C] placeholder:text-[15px] placeholder:font-medium'
              containerClassName='h-[60px] relative shadow-[0px_2px_8px_0px_rgba(73,75,84,0.05)] p-4 border border-[#E2E2E2] w-full focus-within:border-[#FFC87B] focus-within:shadow-[0px_2px_6px_0px_rgba(255,150,6,0.19)]'
              placeholder={formContext.placeholder}
              onChange={(e) => handleInputChange(e, setFieldValue)}
              value={phoneNumber || username}
              Icon={() => (phoneNumber ? <CountryCode /> : null)}
              PlaceholderIcon={() => <RxPerson color={ICON_COLOR.gray} size={ICON_SIZE.md} />}
            />
            {recaptchaData?.status && (
              <Captcha id='recaptcha' onChange={setFieldValue} ref={captchaRef} />
            )}
          </AuthForm>
        </Form>
      )}
    </Formik>
  );
}
