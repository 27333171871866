'use client';

import { AxiosError } from 'axios';

import { getCookie } from '@/lib/cookies';
import { APIErrorResponseGenerator, isGuestUser } from '@/lib/helper';

import Loading from '@/components/@base/loading';
import { LOADING_COLORS } from '@/components/@base/loading/type';
import { showToast } from '@/components/@base/toast/show-toast';
import { Text } from '@/components/@base/typography';
import { LOGIN_BY } from '@/components/app/auth/components/@shared/login-options/type';
import { usePostUserValidation, usePostVerificationCode } from '@/components/app/auth/services/hooks';

import { STEP, useAuthStepStore } from '@/store/auth.store';

import { pageLevelLocalization } from '@/constant/localization';
import { PRECONDITION } from '@/constant/status-codes';
import StorageKey from '@/constant/storage-key';

const { login: loginLocalization } = pageLevelLocalization;
type Props = {
  loginBy: LOGIN_BY,
  recaptchaToken?: string
}

export default function LoginOptions({ loginBy, recaptchaToken }: Props) {
  const {
    setStep,
    phoneNumber,
    username,
    setShouldEnterUsername,
    setEnterByPassword,
    setRecoveryMode,
    userNotExist,
    setUserNotExist
  } = useAuthStepStore();
  const token = getCookie(StorageKey.TOKEN);
  const { mutate: postUserValidation, isPending: postUserValidationPending } =
    usePostUserValidation();
  const { mutate: postVerificationCode, isPending: postVerificationCodePending } =
    usePostVerificationCode();

  const handleUserValidation = (recaptcha: string) => {
    const body: any = { username: username || phoneNumber };
    if (recaptcha) {
      body['reCaptcha'] = recaptcha;
    }
    setRecoveryMode(false);
    if (loginBy === LOGIN_BY.PASSWORD) {
      setEnterByPassword(true);
    } else {
      setEnterByPassword(false);
    }
    if (phoneNumber || username) {
      postUserValidation(
        body,
        {
          onSuccess(data) {
            setUserNotExist(!data.userExists);
            if (data.error || !data.userExists) {
              showToast({ message: data.error || loginLocalization.youHaveToRegister });
              setStep(data.nextStep);
            } else {
              if (loginBy === LOGIN_BY.PASSWORD) {
                setStep(STEP.AUTH_STEP_PASSWORD_INPUT);
              } else if (loginBy === LOGIN_BY.CODE) {
                setStep(STEP.AUTH_STEP_START);
              }
            }
          },
          onError(e) {
            if (e instanceof AxiosError) {
              if (e?.response?.status === PRECONDITION) {
                setStep(STEP.AUTH_STEP_START);
              }
              showToast(APIErrorResponseGenerator(e));
            }
          }
        }
      );
    } else {
      setStep(STEP.AUTH_STEP_START);
      setShouldEnterUsername(true);
    }
  };

  const handleCodeLogin = () => {
    setRecoveryMode(false);
    if (username) {
      postVerificationCode(
        { username },
        {
          onSuccess(data) {
            setStep(STEP.AUTH_STEP_SEND_CODE);
            showToast({ message: data?.message, type: 'success' });
          },
          onError(e) {
            if (e instanceof AxiosError) {
              if (e?.response?.status === PRECONDITION) {
                setStep(STEP.AUTH_STEP_START);
              }
              showToast(APIErrorResponseGenerator(e));
            }
          }
        }
      );
    } else {
      setStep(STEP.AUTH_STEP_START);
      setEnterByPassword(false);
    }
  };

  const onClickHandler = () => {
    if (loginBy === LOGIN_BY.PASSWORD) {
      handleUserValidation(recaptchaToken);
    } else if (loginBy === LOGIN_BY.CODE) {
      handleCodeLogin();
    }
  };

  if (userNotExist || (token && !isGuestUser())) {
    return null;
  } else if (postUserValidationPending || postVerificationCodePending) {
    return <Loading color={LOADING_COLORS.ORANGE} className="h-[25px]" />;
  }
  return (
    <Text
      className="cursor-pointer border-b border-dashed border-[#6097F3] text-[12px] text-[#6097F3]"
      onClick={onClickHandler}
      bold
    >
      {loginBy === LOGIN_BY.PASSWORD
        ? loginLocalization.loginByPass
        : loginLocalization.loginByCode}
    </Text>
  );
}
